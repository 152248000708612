<template>
  <textarea
    :value.prop="value"
    @input="handleChange"
  />
</template>

<script>
import autosize from 'autosize'

export default {
  name: 'AutosizeTextarea',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value(newVal) {
      if (newVal === '') {
        this.$nextTick(() => {
          autosize.update(this.$el)
        })
      }
    }
  },
  mounted() {
    autosize(this.$el)
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
